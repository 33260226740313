/* Footer.css */
.footer {
    background-color: #e0f5f1;
    color: #0a0202;
    padding: 40px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .footer-section {
    flex: 1;
    margin: 0 20px;
    text-align: left;
  }
  
  h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  p {
    line-height: 1.5;
  }
  
  a {
    color: #0f0303;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  a:hover {
    color: #ff9900;
  }
  
  .footer-bottom {
    margin-top: 20px;
    font-size: 14px;
  }
  
  /* Additional styling as needed */
  .logo {
    max-width: 65px;
    height: auto;
    margin-right: 2px; /* Adjust as needed */
  }