.App {
  text-align: center;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.head{
  background-color: #eac2d9;
  background-image: linear-gradient(0deg, #ceefa4 0%, #798d9b 100%);
  ;
  color: #191816;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.sght{
  font-weight: lighter;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-style: oblique;
}
.sghts{
  font-weight: bold;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-style: oblique;
}

.facts {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.aboutus {
  max-width: 84%;
  height: auto;
  object-fit: cover;
}

.services {
  max-width: 67%;
  height: auto;
  object-fit: cover;
}

.abctrip{
  background-color: #f4edf1;
  background-image: linear-gradient(0deg, #f9a2a2 0%, #9ea1e3 100%);
  ;
  color: #191816;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.raratrip{
  background-color: #cde5d1;
  background-image: linear-gradient(0deg, #b1e9f1 0%, #f3ccf9 100%);
  ;
  color: #191816;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}