/* TripCard.css */
.trip-card {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    text-align: center;
    background-color: #A9C9FF;
background-image: linear-gradient(180deg, #c7d5ed 0%, #ffffff 50%, #eef8e5 100%);
border: none;
box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

  }
  
  .trip-card img {
    max-width: 70%;
    height: auto;
  }
  
  .read-more-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .read-more-btn:hover {
    background-color: #0056b3;
  }
  
  .trip-details {
    margin-top: 10px;
  }
  .trip-details p{
    text-align: center;
  }