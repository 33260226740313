/* AboutPage.css */
.about-page {
    padding: 40px;
  }
  
  .animated-heading {
    font-size: 2.5rem;
    text-align: center;
    animation: slideIn 1s ease-in-out;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
  }
  
  .image-container {
    flex: 1;
  }
  
  .image-container img {
    max-width: 40%;
    height: auto;
    border-radius: 5%; /* Make the image circular */
    box-shadow: 0px 4px 8px rgba(133, 45, 45, 0.1);
  }
  .text-container {
    margin-top: 2%;
    flex: 1;
    padding: 0 20px;
  }
  
  .text-container p {
    line-height: 1.6;
    text-align:justify;
  }
  