/* src/components/AnimatedCarousel.css */
.carousel-root {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .carousel-slide {
    position: relative;
  }
  
  .carousel-caption {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    transition: opacity 0.3s;
    opacity: 0; /* Initially hidden */
  }
  
  .carousel-slide .carousel-caption {
    opacity: 1; /* Show caption on hover */
  }
  
  .carousel-caption h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .carousel-caption p {
    margin: 5px 0 0;
    font-size: 16px;
    width: auto;
  }
  