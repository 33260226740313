/* src/components/LandingPage.css */
.landing-page {
    position: relative;
    height: 100vh;
    margin-top: 4px !important;
  }
  
  .background-image {
    background-image: url('../../../public/landing.png'); /* Set your background image URL */
    background-size: cover;
    background-position: center;
    height: 100%;
    filter: blur(2px);
    z-index: -1;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .banner {
    background-color: rgba(226, 238, 223, 0.8);
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .banner h1 {
    font-size: 36px;
    margin: 0;
  }
  
  .content {
    text-align: center;
    margin-top: 70px;
  }
  
  .content h4 {
    font-size: 24px;
    color: #262424;
    max-width: 500px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin: 0 auto;
  }
  
  .explore-button {
    margin-top: 12%;
    background-color: #000;
    border: 2px solid #fff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .explore-button:hover {
    background-color: #333;
  }

  /* src/components/LandingPage.css */
.banner h2 {
    font-size: 36px;
    margin: 0;
    animation: moveText 4s ease-in-out infinite alternate;
  }
  
  @keyframes moveText {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(10px);
    }
  }
  