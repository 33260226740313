/* CustomNavbar.css */
.logo {
    max-width: 100px;
    height: auto;
    margin-right: 10px; /* Adjust as needed */
  }
.custom-navbar {
     background-color: #D9AFD9;
    background-image: linear-gradient(0deg, #b0f0ac 0%, #c4e1f6 100%);
    ;
    color: #e8caca;
    font-family: 'Arial', sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .brand-text {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  }
  
  .nav-link {
    color: #0c1736 !important;
    font-size: 16px;
    font-weight: bold;
    margin: 0 10px;
    transition: color 0.3s ease;
    
  }
  
  .nav-link:hover {
    color: #f2aa4cff !important;
  }
  
  .social-icons .icon {
    font-size: 24px;
    margin: 0 8px;
    transition: color 0.3s ease;
  }
  
  .social-icons .icon:hover {
    color: rgb(96, 161, 251);
  }
  
  .brand-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  
  .brand-text {
    text-align: center;
    margin: 10px;
  }
  
  .brand-line1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: rgb(21, 21, 21);
  }
  .brand-line2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: rgb(0, 0, 0);
  }
  .infs{
    margin-top: 3%;
  }